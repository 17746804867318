/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {YouTube, Gallery, GalleryImage, gallerySettings, L, AddToCalendarDiv} from "@src/style/mdxStyle.js";
import {CompetitionDate} from '@components/utils';
import AddToCalendar from "react-add-to-calendar";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "landscape-photography-competitions-calendar",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#landscape-photography-competitions-calendar",
    "aria-label": "landscape photography competitions calendar permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Landscape photography competitions calendar"), "\n", React.createElement(_components.p, null, "In this article I have compiled a list of big competitions that include categories related to ", React.createElement(_components.strong, null, "landscape and nature"), " photography. If the dates for deadline are known, there will also be a button to add event to your favorite calendar. Feel free to put some reminders."), "\n", React.createElement(_components.p, null, "If some of the information about the competition is outdated or incorrect, please send me a message. I will also gladly add more competitions, send me worthy candidates."), "\n", React.createElement(_components.h2, {
    id: "competitions-2020",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#competitions-2020",
    "aria-label": "competitions 2020 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Competitions 2020"), "\n", React.createElement(_components.p, null, "There are lots of competitions online and many websites that let you search for new competitions. Many of them might work for landscape photography, but there can be a few caveats, which is why I would rather recommend to apply to well known awards."), "\n", React.createElement(_components.p, null, "Lower-grade competitions often have some clauses in rules that effectively can steal rights to your work. You would still retain authorship, but competition organizers will be able to use your image for prints, cards, ads and so on. Better known awards usually also ask for some rights, but they are more specific and say right from the start that images can only be used to advertise the competition itself and not used on any kind of postcards or prints for sale."), "\n", React.createElement(_components.p, null, "Still, be sure to always ", React.createElement(_components.strong, null, "read the rules"), "."), "\n", React.createElement(_components.h3, {
    id: "natural-landscape-photography-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#natural-landscape-photography-awards",
    "aria-label": "natural landscape photography awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Natural landscape photography awards"), "\n", React.createElement(_components.p, null, "This competition is a little special because it focuses on realism in photography – not something favoured by everyone nowadays. There aren't many limitations in the rules, the rule of thumb is that ", React.createElement(_components.strong, null, "\"The integrity of the subject should be maintained.\"")), "\n", React.createElement(_components.p, null, "In practice this means that such techniques as compositing, stretching mountains, or combining different focal distances and times are not allowed. Though it's still permitted to blend exposures or remove dust and lens flares, as long as the integrity of subject is maintained. You can find more details on the website."), "\n", React.createElement(_components.p, null, "Competition awards Natural Landscape Photographer of the Year for best 6 images, Natural Landscape Photograph of the Year and Youth Photographer of the Year."), "\n", React.createElement(_components.p, null, "Competition is organized by ", React.createElement(_components.a, {
    href: "https://www.mattpaynephotography.com/"
  }, "Matt Payne"), ", ", React.createElement(_components.a, {
    href: "http://www.timparkin.net/"
  }, "Tim Parkin"), ", ", React.createElement(_components.a, {
    href: "https://www.alexnail.com/"
  }, "Alex Nail"), " and Rajesh Jyothiswaran."), "\n", React.createElement(_components.p, null, "Panel of judges include ", React.createElement(_components.a, {
    href: "http://www.joecornishgallery.co.uk/"
  }, "Joe Cornish"), ", ", React.createElement(_components.a, {
    href: "https://portfolios.williamneill.com/index"
  }, "William Neill"), ", ", React.createElement(_components.a, {
    href: "https://www.naturephotoguides.com/"
  }, "Sarah Marino"), ", ", React.createElement(_components.a, {
    href: "https://alisterbenn.com/"
  }, "Alister Benn"), ", ", React.createElement(_components.a, {
    href: "https://www.alexnoriega.com/"
  }, "Alex Noriega"), ", ", React.createElement(_components.a, {
    href: "https://www.adamgibbs.com/"
  }, "Adam Gibbs"), ", ", React.createElement(_components.a, {
    href: "https://www.stefanforster.com/"
  }, "Stefan Forster"), " and ", React.createElement(_components.a, {
    href: "https://www.bartocha-photography.com/"
  }, "Sandra Bartocha"), "."), "\n", React.createElement(_components.p, null, "Categories: Grand Landscape, Intimate and Abstract, Nightscape, Aerial, Project. There is no limitation on when the image was taken."), "\n", React.createElement(_components.p, null, "Gallery of images from judges, until there are winners available:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://cdn.shortpixel.ai/spai/w_864+q_glossy+ret_img+to_webp/https://naturallandscapeawards.com/wp-content/uploads/2021/02/Millbank-20-11-06-DSC06243.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://cdn.shortpixel.ai/spai/w_637+q_glossy+ret_img+to_webp/https://naturallandscapeawards.com/wp-content/uploads/2021/01/Spring-storm-Yosemite-Valley-Yosemite-National-Park-California-1986__%C2%A9William-Neill.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://cdn.shortpixel.ai/spai/w_637+q_glossy+ret_img+to_webp/https://naturallandscapeawards.com/wp-content/uploads/2021/02/Sarah-Marino-Salt-Cubes-DV-2048px-Watermark.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://h8y5r5p2.stackpathcdn.com/wp-content/uploads/2021/02/ariandel-1024x732.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://h8y5r5p2.stackpathcdn.com/wp-content/uploads/2021/02/122976221_977041432706634_7708896280239504741_o-1024x547.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://h8y5r5p2.stackpathcdn.com/wp-content/uploads/2021/02/stefan-forster-3.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://h8y5r5p2.stackpathcdn.com/wp-content/uploads/2021/02/145579586_3697176447028148_4553922594895988477_o.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://naturallandscapeawards.com/"
  }, "https://naturallandscapeawards.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 40$ for 6, 60$ for 12, 100$ for 18 images"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": Photographer of the year: 5000$ (1000$ second place), Photograph of the year 1000$, Youth photographer of the year 500$, each category winner 500$, Project winner 1000$."), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "June 1, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "September 1, 2021 00:00 UTC",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Natural landscape photography awards",
      description: "https://naturallandscapeawards.com/",
      startTime: "2021-09-01T00:00:00-00:00",
      endTime: "2021-09-01T00:01:00-00:00",
      location: "https://naturallandscapeawards.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "sony-world-photography-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sony-world-photography-awards",
    "aria-label": "sony world photography awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sony World Photography Awards"), "\n", React.createElement(_components.p, null, "Sony runs Professional, Open, Student and Youth competitions. For ", React.createElement(_components.strong, null, "Professional"), " you need to send series of images and it seems to be more for stories rather than landscapes. ", React.createElement(_components.strong, null, "Open"), " accepts single images."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Professional - Recognizing outstanding bodies of work"), "\n", React.createElement(_components.li, null, "Open - Rewarding exceptional standalone images"), "\n"), "\n", React.createElement(_components.p, null, "Past years landscape and nature winners:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.worldphoto.org/sites/default/files/gallery/images/1839330/17ddabbaf1c17c1e7035bb27739b8ab2._DSC4-3859-Oz_0.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.worldphoto.org/sites/default/files/gallery/images/1099579/e306cceef9ce8e9b110cff7835111c6c.hgrx1201803160157-63v2_0.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.worldphoto.org/sites/default/files/gallery/images/749917/498ce9c12a4a589fb514732cd5924971.Early%2520autumn_0.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.worldphoto.org/sites/default/files/190283_206440_5_%20%C2%A9%20Hiroshi%20Tanita%2C%20Japan%2C%20Winner%2C%20Open%20Competition%2C%20Nature%2C%202017%20Sony%20World%20Photography%20Awards.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.worldphoto.org/sites/default/files/Markus%20van%20Hauten%2C%20Germany%2C%20Winner%2C%20Open%2C%20Panoramic%2C%202016%20Sony%20World%20Photography%20Awards_0.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://www.worldphoto.org/sony-world-photography-awards"
  }, "https://www.worldphoto.org/sony-world-photography-awards")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": Free for first 3 images"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": A total prize fund of $60,000 (USD) plus the latest Sony digital imaging equipment is shared between the winning photographers."), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 April, 2020",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "January 7, 2021 13:00 GMT",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Sony World Photography Awards",
      description: "https://www.worldphoto.org/sony-world-photography-awards",
      startTime: "2021-01-07T12:00:00-00:00",
      endTime: "2021-01-07T13:01:00-00:00",
      location: "https://www.worldphoto.org/sony-world-photography-awards"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "siena-international-photo-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#siena-international-photo-awards",
    "aria-label": "siena international photo awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Siena international photo awards"), "\n", React.createElement(_components.p, null, "This competition have been growing a lot over the last years, many great photographers apply yearly, in 2020 they got 48000 submissions from all over the world. 10 different categories, including ", React.createElement(_components.strong, null, "The beauty of nature"), " and ", React.createElement(_components.strong, null, "Animals in their environment")), "\n", React.createElement(_components.p, null, "Past years winners:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/ltXnwS93AUEJDQEE9iyCdZEDuzAr9IxC.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/mFNxQEkrIjcoi3OEaedcBSCZZ7d8Eaxz.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/fhcAEPTNj4PQpXPTSu6V7mAVeV8xe3VK.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/ARsghR0dlKOWLl7sT8Ig9rs24WxhO5lk.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/Ys42Egy4307kVZbMsamZpdkGfgoJtQUz.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/RH4ZcP1Ef3dbo7n0dfXF4wFyQuK9ThCn.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://server.sienawards.com/plesk-site-preview/old.sipacontest-old.com/89.46.66.37/data/images/eedf65ad7317bd0496ebb22f031dd14d.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://server.sienawards.com/plesk-site-preview/old.sipacontest-old.com/89.46.66.37/data/images/768ea8228f5d5eb395869c21a568ac32.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://server.sienawards.com/plesk-site-preview/old.sipacontest-old.com/89.46.66.37/data/images/83ad9c35fb2dcb4390b8bd77964e6d6b.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://sipacontest.com/"
  }, "https://sipacontest.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 1 image free, 10$ per image after (8$ before 1 December, 2020)"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": $1.500 worth of photography equipment, statuettes and ticket to Siena with paid stay"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "25 October, 2020",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "24 January, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Siena international photo awards",
      description: "https://sipacontest.com/",
      startTime: "2021-01-24T00:00:00-00:00",
      endTime: "2021-01-24T00:01:00-00:00",
      location: "https://sipacontest.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "drone-photo-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#drone-photo-awards",
    "aria-label": "drone photo awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Drone photo awards"), "\n", React.createElement(_components.p, null, "This is a competition dedicated to aerial photography. With so many people shooting great images on drones, this is the competition to enter your great aerials. Competition is also run by Siena photo awards, but dates and theme is different."), "\n", React.createElement(_components.p, null, "Past years winners:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/SXpXVSybQAyAHW7iXpB6StQQXFwOP7e3.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/ZaiJSH366va770H8L1eL4dGDQfxqLKUr.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/rbK89s4zQrmczs2fludKqClVj5lnbuuK.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/fvDoB4KaZVhfhmVuN1fsifF3w8kLZx2J.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/PzjzqqYE0VuSRcwtanyomRO9VOQoEm3E.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/IP6uBnGV1IVp4mVMzXKADvjah0bLnfBW.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/ElqOCIeYmMYwhoTgfyORvkYwBrk243Ww.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/fYmMVq6a8P0elKUDWxVokSTJauan5t15.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://img.artphototravel.com/medium/ACv29TsAKtha0oIKTqEyaNG8g1dMqY2s.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://droneawards.photo/"
  }, "https://droneawards.photo/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 1 image free, 10$ per image after (8$ before 20 May, 2020)"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": $500 worth of photography equipment, statuettes and showcase in exhibition"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 May, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "15 June, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Drone photo awards",
      description: "https://droneawards.photo/",
      startTime: "2021-06-15T00:00:00-00:00",
      endTime: "2021-06-15T00:01:00-00:00",
      location: "https://droneawards.photo/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "moscow-photo-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#moscow-photo-awards",
    "aria-label": "moscow photo awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Moscow photo awards"), "\n", React.createElement(_components.p, null, "A somewhat unique chance to be introduced to the creative community in Russia. Categories are basically endless, so almost any images can be applied. You can apply as ", React.createElement(_components.strong, null, "Professional"), ", ", React.createElement(_components.strong, null, "Emerging/Non-professional"), " and as ", React.createElement(_components.strong, null, "Student"), "."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/large/1485293170_1400_2contest.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/large/1492695825_Odyssey_of_one_blossoming_Avenue_II.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/92475/10-16987-18/full/50414e8017693753e383bc48b2643c0e.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/94373/10-16246-17/full/9f910206a7030fdbd12b169df1cbe15a.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/88384/10-23618-19/full/0d845d4c3f8287c4b1371893d3d385eb.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/93714/10-22659-19/full/c05f1a1679f4817c1f33636f292cb3eb.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/89815/10-21684-19/full/60e8030bb95274f2265da312439c2de0.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/96067/10-19793-18/full/ab4d3cfabb75332668d9def0aaee51c0.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://s3-us-west-2.amazonaws.com/moscowfotoawards/upload/96014/10-19711-18/full/27788ce474298f1a0c0e8ea482b7c8f2.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://www.moscowfotoawards.com/"
  }, "https://www.moscowfotoawards.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 20$/image for Professional and 15$ for Emerging"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": Photographer of the year $3000, New Talent $2000, Category winner $100"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 September, 2020",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "15 May, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Moscow photo awards",
      description: "https://www.moscowfotoawards.com/",
      startTime: "2021-05-15T00:00:00-00:00",
      endTime: "2021-05-15T00:01:00-00:00",
      location: "https://www.moscowfotoawards.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "close-up-photo-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#close-up-photo-awards",
    "aria-label": "close up photo awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Close-up photo awards"), "\n", React.createElement(_components.p, null, "This is a fresh competition that started just 2 years ago in 2019. They run one big yearly competition and several smaller competitions in between. The main theme is close-up photography, but if you are not into macro, you can still apply for Intimate Landscape category."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1558210385895-L6UEWCDQ0FEV54HCBA8Y/ke17ZwdGBToddI8pDm48kB6N0s8PWtX2k_eW8krg04V7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1URWK2DJDpV27WG7FD5VZsfFVodF6E_6KI51EW1dNf095hdyjf10zfCEVHp52s13p8g/Emanuele-Biggi-Namibs-Gaze-CUPOTY-1800px.jpg?format=2500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1558010321491-8ODF6BP7THYI4Z1MN68B/ke17ZwdGBToddI8pDm48kL87Xd7i_PpTopEgTg22d0BZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpxhtACYwGxhHx0xaocef0UhaufL7oyZGk6Rv9Y-r9uEwdwBvybszOikEwBqlQb-7MU/animals-giesbers-edwin-orange-tip.jpg?format=1500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1558098681318-AGFD1V0N16URHXNH9S1Y/ke17ZwdGBToddI8pDm48kPqQfq0L3n3wpHIsRapTfg8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKczo5Zn4xktlpMsMj-QlHXeMfNK6GwvtVkYEWiR8XAPyD3GfLCe_DXOSC_YcAacWL_/Henri-Koskinen-Seeds-on-Stage-CUPOTY.jpg?format=2500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1558012386352-SH0I2AGDAJ5MPGI8XG9S/ke17ZwdGBToddI8pDm48kDl9oBB22hZPIrM3rMTX5ptZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpxrXmNh4cztqklcrN_-_JBheSxsp0vsYImQYaSKNPK97wcvfmFpln_dORDEGSng_AE/plants-ivanov-aleksandr-dancing-matches.jpg?format=1500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1558091868520-R1CD8Z26OQDANWH82ZZB/ke17ZwdGBToddI8pDm48kMR1yAHb8bPoH1-OdajP2rZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpya-Yv2-AUIrtyc_1d9d84jKAfrgH35AgL5HDK7jKFTEAbkVb2mHFRzAaMt_-j0ggg/landscape_Jakubowski_Radomir_Tree_of_life.jpg?format=1500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1558091845820-D85X26W3VZBR3MRMVXN4/ke17ZwdGBToddI8pDm48kMR1yAHb8bPoH1-OdajP2rZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpya-Yv2-AUIrtyc_1d9d84jKAfrgH35AgL5HDK7jKFTEAbkVb2mHFRzAaMt_-j0ggg/animals-koskinen-henri-ammonite-fossil.jpg?format=1500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1599728393410-VBEW31FD1457KFVM6GYW/ke17ZwdGBToddI8pDm48kJeJHJwC6a6EsSJOBBjFhV9Zw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpzDyK0yyeJKIY_i0u68MjTVXsF0JxhrS6Mqua5wjwegnaK2LCQsTlqKP_tB0-z4t8c/Anna-Ulmestrand-Bullet-CUPOTY02.jpg?format=1500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1599729242057-MRSOYS01WGL0DKR2ZVOB/ke17ZwdGBToddI8pDm48kCnCwF66OlpzuadSbISAgjdZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpxPoolKGS-Q7uLSyqhjTyWmhHWCDHSc38_VRRvIRig9uk1uiFfPWdSGgvyUkibUWgw/Edwin-Giesbers-ice-crystals-window-CUPOTY02.jpg?format=1500w"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://images.squarespace-cdn.com/content/v1/5aae554545776e4f59c31b2c/1599737193870-M1KU5WSVWSB5MWL5N0R1/ke17ZwdGBToddI8pDm48kAB7e_RXwP7W1JYOQe4i1chZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwipEFWRfFhgKUqdhOMvLsuLIIF8CxP7msiIEXGxwdffO4dc9amm8buQexIrCXU3fE/Intimate+landscape-Zhang-lihua-Oil-+painting-of-ice.jpg?format=1500w"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://www.cupoty.com/"
  }, "https://www.cupoty.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 1 image £10, 3 images £15"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": Photographer of the year £2,500"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 January, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "01 May, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Close-up photo awards",
      description: "https://www.cupoty.com/",
      startTime: "2021-05-01T00:00:00-00:00",
      endTime: "2021-05-01T00:01:00-00:00",
      location: "https://www.cupoty.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "nature-photographer-of-the-year",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nature-photographer-of-the-year",
    "aria-label": "nature photographer of the year permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Nature photographer of the year"), "\n", React.createElement(_components.p, null, "The major focus of the competition is nature, so no zoos or pet images are allowed. Keeping that in mind, there are lots of various categories to choose from to send your images to."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/08/25-C5_The_marble_palace_Theo-Bosboomtif.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/08/22-C5-Ripples-Markus-van-Houten.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/08/23-C5-dune-before-sunrise-stephan-fuernrohr.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/08/Nature_Photographer_of_the_Year_Contest_2017-NPOTY-C5_1-Remo_Savisaar-Early_morning_bog-1.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/11/C03_NPOTY-2018_Bastien-Riu_Flight_Winner_35581.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/11/C05_NPOTY-2018_Uge-Fuertes-Sanz_Autumn-river_Highly-commended_32003.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2018/11/C05_NPOTY-2018_Pere-Soler-Isern_Frozen-world_Winner_43150.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2019/11/C05_68441_Landscapes_winner.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://naturephotographeroftheyear.com/wp-content/uploads/2019/11/C05_76054_Landscapes_finalist.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://naturephotographeroftheyear.com/"
  }, "https://naturephotographeroftheyear.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 1 image €27,50"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": Category winner: €500, Overall winner: €2500"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 December, 2020",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "30 May, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Nature photographer of the year",
      description: "https://naturephotographeroftheyear.com/",
      startTime: "2021-05-30T00:00:00-00:00",
      endTime: "2021-05-30T00:01:00-00:00",
      location: "https://naturephotographeroftheyear.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "nd-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nd-awards",
    "aria-label": "nd awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ND awards"), "\n", React.createElement(_components.p, null, "There are two main categories to enter - ", React.createElement(_components.strong, null, "Professional"), " and ", React.createElement(_components.strong, null, "Non-Professional"), ". Both include Landscape/Nature sub-category. From the winning images, it looks like images with long exposure and use of ND-filters are more in favour. There are also lots of black and white entires and series of images."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/14571001994m0kndaniel_rericha_img_7822.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/1474823683rhnpzcueva_y_3_cimas_copia.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/1474800997pj8icroppel_simon_5.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/1474837130t63gkalexandre_manuel_shrine_study_1.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/1494678606j6tnsdangerous_hobby.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/1506190884b4zvrcurran_jack_in_the_light_of_the_dunes.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/15059138444dqa5puzzle_weihao_pan.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/1569189233y5vjyrinaldi_nico_.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://ndawards.net/upload/images/15534041433mdxglamb_anthony_intimate.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://ndawards.net/"
  }, "https://ndawards.net/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": $25/image Professional, $20/image Non-professional, 10$ per additional category applied"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": Professional category winner $500, overall winner $2500 / Non-professional category winner $300, overall winner $1300"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 February, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "19 September, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "ND awards",
      description: "https://ndawards.net/",
      startTime: "2021-09-19T00:00:00-00:00",
      endTime: "2021-09-19T00:01:00-00:00",
      location: "https://ndawards.net/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "landscape-photographer-of-the-year",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#landscape-photographer-of-the-year",
    "aria-label": "landscape photographer of the year permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Landscape photographer of the year"), "\n", React.createElement(_components.p, null, "The name basically says it all. There is one catch, it's a British landscape photographer of the year, so all images should be taken in UK in the past 5 years. You can apply worldwide though."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/classic-view/_galleryFullImage/AC_0012836_N_2_A.JPG"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/classic-view/_galleryFullImage/AC_0020214_Chris-Herring.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/classic-view/_galleryFullImage/AC_0009252_Josh-Cooper.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/e53689b7-bf9e-41e3-841e-859af87ca934.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/your-view/_galleryFullImage/AY_0003370_Colin-Bell.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/2018-gallery/_galleryFullImage/Brian-Kerr-Standing-in-Snow-Leadhills-South-Lanarkshire.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/2018-gallery/_galleryFullImage/AC_0002411_JonesCD.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/2020-gallery/_galleryFullImage/3028.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://www.lpoty.co.uk/images/galleries/2020-gallery/_galleryFullImage/LPOTY-WINNER-Adult-Winner-Chris-Frost.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://www.lpoty.co.uk/"
  }, "https://www.lpoty.co.uk/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": £9.99/image, £24.99/8 images"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": LPOTY £10,000, category winner £1,000, 2nd category place £500"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "13 January, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "14 April, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Landscape photographer of the year",
      description: "https://www.lpoty.co.uk/",
      startTime: "2021-04-14T12:00:00-00:00",
      endTime: "2021-04-14T12:01:00-00:00",
      location: "https://www.lpoty.co.uk/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "big-picture-competition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#big-picture-competition",
    "aria-label": "big picture competition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Big Picture competition"), "\n", React.createElement(_components.p, null, "Open to all photographers worldwide, this competition is presented by California Academy of Science. You can apply 10 images per account and have to pay for 10 images. Grand prize is $5000."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/592e425ce3ec4d4239d43af2_Jodi-Frediani---Frediani_J_ArtofNature_SeaJewels.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/592e47d99c929c1e5f55d2a8_BP17_Winner_WingedLife_DeniseIppolito.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/5ae5fd2fe789f4689add0539_BP18_1280X855_FirstPlace_HenriKoskinen.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/583a641245569b016c4bf858_Maroesjka-Lavigne---Maroesjka-Lavigne_LON06.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/583a6205d36bab22572c682c_Peter-Juzak---Microscopic-view-of-Sulfur-crystals-in-polarized-light120x.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/5cc503d0b2ea2e35f532769b_BP19_1280X855_GrandPrize_Rikardson.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/5cc50c5c7c5efa52c2d53037_BP19_1280X855_FirstPlace_Salvadori.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/5cc50f70dca8012c0a058cb8_BP19_1280X855_FirstPlace_Naskrecki.png"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://assets.website-files.com/57f5df04bf7667d94bc8e3c4/5ea86d881aa508dd65aa3283_ANDY%20PARKINSON%20-%20Parkinson_Andy_hires.png"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://bigpicturecompetition.org/"
  }, "https://bigpicturecompetition.org/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": $25/10 images, $15 story"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": Best overall image $5000, category winner $1000"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 December, 2020",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "01 March, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Big Picture competition",
      description: "https://bigpicturecompetition.org/",
      startTime: "2020-03-01T00:00:00-08:00",
      endTime: "2021-03-01T11:59:00-08:00",
      location: "https://bigpicturecompetition.org/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "monovision-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#monovision-awards",
    "aria-label": "monovision awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "MonoVision awards"), "\n", React.createElement(_components.p, null, "As the name implies, only black and white images are allowed in this competition. Other then that, categories are very diverse. You can apply either into single images competition or into series, where series is up to 8 images."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/157908099994y5_contest.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/15788389684rmny2020_monovisions_lone_tree_in_yellowstone_1_of_1_.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/1586974010c5vlhchamois_rupicapra_rupicapra_.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/1586169787qmgprboss.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/15576972203vt81romain_tornay_elements.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/1541044900z-c5lchen_su_light_and_shadows.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/1557697577_nfl1romain_tornay_fences.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/1524840530bhuf8dsc01870_edit.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monovisionsawards.com/upload/images/1497222175bidhufrozenmono.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://monovisionsawards.com/"
  }, "https://monovisionsawards.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": $25 series / $20 single images, fee is higher after 01 January"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": $3000 series winner, $2500 singe image winner"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 Jan, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "16 May, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "MonoVision awards",
      description: "https://monovisionsawards.com/",
      startTime: "2021-05-16T00:00:00-00:00",
      endTime: "2021-05-16T00:01:00-00:00",
      location: "https://monovisionsawards.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "monochrome-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#monochrome-awards",
    "aria-label": "monochrome awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Monochrome awards"), "\n", React.createElement(_components.p, null, "This competition also focuses on black and white images with many categories available to enter. You can apply as Professional or as Amateur."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/1562200704v9yklabyss.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/1563890602wp442lonelyisland_markuscasutt.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/15203544250dcu5evans_kevin_3.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/15301884774cklyreynisfjara_black_sand_beach._vik.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/1444044944nz-2oluke_tscharke_barossa_bolt.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/1448836194fb7s_afghanistan_damien_looney.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/1408699942ax4guhans_strand_river_delta.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/15738975950qhn1the_shining_vii_sylvester_grey.jpg"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://monoawards.com/upload/images/1560694240n5v6iraw_2280_edit.jpg"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://monoawards.com/"
  }, "https://monoawards.com/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": $25/image Professional, $20/image Amateur"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": $2000 overall professional winner, $1000 overall amateur winner"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "01 Jan, 2021",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "14 November, 2021",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "Mono awards",
      description: "https://monoawards.com/",
      startTime: "2021-11-14T00:00:00-00:00",
      endTime: "2020-11-14T00:01:00-00:00",
      location: "https://monoawards.com/"
    }
  })), "\n", React.createElement(_components.h3, {
    id: "british-photography-awards",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#british-photography-awards",
    "aria-label": "british photography awards permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "British photography awards"), "\n", React.createElement(_components.p, null, "This competition is only open to Brits. You can live anywhere, but you have to be British to apply. There are some strict ethical and editing requirements, and there are no prizes – money from entries and resulting event tickets are spread among many British charities."), "\n", React.createElement(_components.p, null, "Past years winners and mentions:"), "\n", React.createElement(Gallery, gallerySettings, React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/a4bf0599-ed7b-461c-936d-6acccc99dd6b.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/9042c004-298f-4ea0-8463-8306a9d7d802.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/c27d55bf-16bd-4c0a-9428-b858cfee2a64.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/e53689b7-bf9e-41e3-841e-859af87ca934.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/Submissions/44b7d085-244a-4a61-8157-3d9a984ad708/645ee761-d4e4-4b19-a567-40036819f538.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/Submissions/191c7d24-d51a-4c3a-96bd-924aa547eaaf/5d7d179a-12d8-48fa-9d88-7e632ab320fc.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/Submissions/a837589e-2703-46c5-b4a2-26cc3032a65e/6b37a94d-1551-4970-b125-a4247b161114.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/Submissions/c26d4672-ab1a-459d-9e37-495a48afbda2/bdfc9b1a-e009-4351-a51e-2d14443cf00d.jpg?width=1600"
  }), React.createElement(GalleryImage, {
    alt: "Gallery image",
    src: "https://britishphotography-images.azureedge.net/Submissions/84d10cc8-0871-4899-8cc8-33f585bb5ccf/43c7acef-2f21-4b0e-af2f-7e98f8189c92.jpg?width=1600"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Website: ", React.createElement(_components.a, {
    href: "https://britishphotographyawards.org/"
  }, "https://britishphotographyawards.org/")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Entry Fee"), ": 1 image £5"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Prizes"), ": None, but 90% of the money from entries and event tickets go to charities"), "\n", React.createElement(_components.li, null, "Submission open: ", React.createElement(CompetitionDate, {
    date: "03 August, 2020",
    pastMessage: "Open now"
  })), "\n", React.createElement(_components.li, null, "Deadline: ", React.createElement(CompetitionDate, {
    date: "03 November, 2020",
    pastMessage: "Already closed"
  })), "\n"), "\n", React.createElement(AddToCalendarDiv, null, React.createElement(AddToCalendar, {
    buttonLabel: "Add to calendar",
    event: {
      title: "British photography awards",
      description: "https://britishphotographyawards.org/",
      startTime: "2021-11-03T00:00:00-00:00",
      endTime: "2021-11-03T00:01:00-00:00",
      location: "https://britishphotographyawards.org/"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
