import React from "react"

export const CompetitionDate = ({ date, pastMessage }) => {
  const isBefore = new Date(date) < new Date()
  if (isBefore) {
    if (pastMessage)
      return (
        <span>
          {date} ({pastMessage})
        </span>
      )

    return <span>{date} (Already happened)</span>
  }
  return <span>{date}</span>
}
